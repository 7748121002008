import * as React from "react";
import styled from "styled-components";
const VideoHome = styled.video`
  object-fit: cover;
  object-position: 50% 50%;
  display: flex;
  justify-content: center;
  height: 60vh;
`;
const ContainerVideo = styled.div`
  width: 100%;
  height: 60vh;
  object-fit: cover;
  display: flex;
  justify-content: center;
  @media (max-width: 599px) {
    max-width: 100%;
    height: 60vh;
    display: flex;
    justify-content: center;
  }
  @media (min-width: 600px) {
    width: 100%;
    height: 60vh;
    display: flex;
    justify-content: center;
  }
`;
const Home = () => (
  <>
    <div class="flex flex-wrap overflow-hidden">
      <div class="w-full overflow-hidden lg:w-1/2 xl:w-1/2">
        <ContainerVideo className="relative flex items-center justify-center w-full overflow-hidden">
          <div className="relative z-30 p-5 text-8xl text-grayny text-left container font-bold"></div>
          <VideoHome
            autoPlay
            loop
            muted
            preLoad
            className="absolute z-10  top-0 bg-blend-darken"
          >
            <source
              src="https://maycourier.fr/wp-content/uploads/2022/06/elite-training-center.m4v"
              type="video/mp4"
            />
            <source
              src="https://maycourier.fr/wp-content/uploads/2022/06/elite-training-center.webm"
              type="video/webm"
            />
            Your browser does not support the video tag.
          </VideoHome>
        </ContainerVideo>
      </div>

      <div class="bg-granite w-full overflow-hidden lg:w-1/2 xl:w-1/2 p-12">
        <h2 className="text-3xl md:text-4xl lg:text-6xl font-bold py-6 md:py-12 text-white">
          <span className="text-geraniumlake">"</span> Be the best version of,
          <br />
          <span className="uppercase">yourself</span>
          <span className="text-geraniumlake">"</span>
        </h2>
        <p className="text-xl md:text-3xl lg:text-xl py-6 text-white leading-loose md:leading-loose lg:leading-loose uppercase">
          elite training center is the leading premium combat
        </p>
      </div>
    </div>
    <div className="flex flex-wrap overflow-hidden">
      <div className="w-full overflow-hidden  order-1"> </div>

      <div className="w-full overflow-hidden md:w-full lg:w-1/2 xl:w-1/2 order-2 lg:order-3 ">
        <img
          className="w-full object-cover"
          src="https://res.cloudinary.com/elite-training-center/image/upload/q_auto,f_auto,c_scale,w_auto/v1654743536/training%20center/home/smith-machine-elite-training_j0vmit.webp"
          alt="hua hin muay thai team"
        />
      </div>

      <div className="bg-granite w-full overflow-hidden md:w-full lg:w-1/2 xl:w-1/2 p-8 lg:p-24  order-3 lg:order-2">
        <h2 className="text-3xl md:text-4xl lg:text-6xl font-bold py-6 md:py-12 text-white">
          ELITE TRAINING CENTER
        </h2>
        <p className="text-xl md:text-3xl lg:text-xl py-6 text-white leading-loose md:leading-loose lg:leading-loose uppercase">
          elite training center is the leading premium combat, fitness and
          contact sports training facility based in thailand. with 2 locations
          currently operational, 1 in bangkok, which sits on a 2000+ sqm
          facility comprimising of the state of the art fully equipped gym,
          swimming pool, steam room, sauna, locker room and shower facility.
        </p>
      </div>
      <div
        className="w-full overflow-hidden order-4 container mx-auto"
        id="schedule"
      >
        <h2 className="text-3xl md:text-4xl lg:text-6xl font-bold py-12 text-geraniumlake text-center">
          OUR SCHEDULE
        </h2>
        <img
          className="w-full lg:w-2/3 object-cover mx-auto"
          src="https://res.cloudinary.com/elite-training-center/image/upload/q_auto,f_auto,c_scale,w_auto/v1654743535/training%20center/home/classes-elite-training-center_juphnn.webp"
          alt="OUR SCHEDULE bangkok muay thai team"
        />
      </div>
      <div className="w-full overflow-hidden order-5">
        <h2 className="text-3xl md:text-4xl lg:text-6xl font-bold py-12 text-geraniumlake text-center">
          JOIN ELITE TRAINING CENTER
          <br />
          RE-INVENT YOUR BODY AND SPIRIT
        </h2>
      </div>
      <div className="w-full overflow-hidden md:w-full lg:w-1/2 xl:w-1/2 order-6">
        <img
          className="w-full object-cover"
          src="https://res.cloudinary.com/elite-training-center/image/upload/q_auto,f_auto,c_scale,w_auto/v1654743536/training%20center/home/exercises-gym-training-bangkok_sbgyqn.webp"
          alt="exercises gym training bangkok muay thai team"
        />
      </div>

      <div className="bg-granite pt-16 md:pt-20 md:pb-16 p-8 lg:p-20 w-full overflow-hidden  lg:w-1/2 xl:w-1/2 order-7">
        <h2 className="text-4xl md:text-5xl lg:text-6xl font-bold pb-12  text-geraniumlake">
          OUR COACHES
        </h2>
        <p className="text-xl md:text-3xl lg:text-xl py-2 text-white leading-loose md:leading-loose lg:leading-loose uppercase">
          The Elite Training Center team consists of professional coaches.
        </p>
        <p className="text-xl md:text-3xl lg:text-xl pb-6 text-white leading-loose md:leading-loose lg:leading-loose uppercase">
          At Elite Training Center we strive to excel in both mind, body and
          spirit. The all welcoming and inclusive community at Elite Training
          Center is what makes traveler and residents come back to join the
          fight time and time over.
        </p>
      </div>
      <div className="bg-granite pt-16 md:pt-20 md:pb-16 p-8 lg:p-20 w-full overflow-hidden  lg:w-1/2 xl:w-1/2 order-9 lg:order-8">
        <h2 className="text-4xl md:text-5xl lg:text-6xl font-bold pb-12  text-geraniumlake uppercase">
          The Classes
        </h2>
        <p className="text-xl md:text-3xl lg:text-xl py-2 text-white leading-loose md:leading-loose lg:leading-loose uppercase">
          Elite Training center provides a vast variety of fitness and
          performance classes, built to keep you motivated and gradually moving
          your physical abilities to new levels. Choose your classes according
          to your won preference or ask one of our trainers what’s the best
          match for you, to reach your own personal goals and beyond.
        </p>
      </div>

      <div className="w-full overflow-hidden md:w-full lg:w-1/2 xl:w-1/2 order-8 lg:order-9">
        <img
          className="w-full object-cover"
          src="https://res.cloudinary.com/elite-training-center/image/upload/q_auto,f_auto,c_scale,w_auto/v1654743535/training%20center/home/cardio-training-bangkok_ysdqas.webp"
          alt="exercises gym training bangkok muay thai team"
        />
      </div>
    </div>
  </>
);

export default Home;

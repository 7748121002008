import * as React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Home from "../containers/home";

const IndexPage = () => (
  <Layout>
    <Seo title="etc" />
    <Home />
  </Layout>
);

export default IndexPage;
